import React from "react";
import { StatesTypes } from "../types";

const States: React.FC<{ type: StatesTypes }> = ({ type }) => {
  switch (type) {
    case "tracking":
      return (
        <svg
          className="md:w-[300px] sm:w-[200px]"
          width="500"
          height="347"
          viewBox="0 0 197 160"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M99.9359 126.263C131.701 126.263 157.454 100.51 157.454 68.6315C157.454 36.7526 131.588 11 99.9359 11C68.1705 11 42.4179 36.7526 42.4179 68.6315C42.4179 100.51 68.1705 126.263 99.9359 126.263Z"
            fill="#FEFAFB"
          />
          <g filter="url(#filter0_d_393_1071)">
            <path
              d="M142.23 107.709C131.72 119.123 116.661 126.263 99.9358 126.263C86.5875 126.263 74.301 121.716 64.5409 114.077V20.0182C64.5409 16.9068 67.0462 14.3679 70.1716 14.3679H126.032L142.23 30.6218V107.709Z"
              fill="url(#paint0_linear_393_1071)"
            />
          </g>
          <g filter="url(#filter1_d_393_1071)">
            <path
              d="M126.032 15.0454V27.2994C126.032 29.5085 127.823 31.2994 130.032 31.2994H143.23"
              fill="#FBA99D"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131.66 37.8173C132.47 38.909 132.242 40.4508 131.15 41.2611L112.987 54.7416C112.131 55.3766 110.964 55.3891 110.095 54.7726L103.834 50.3309L92.559 57.3971C91.7697 57.8918 90.7688 57.8985 89.9729 57.4146L86.5684 55.3446L78.4422 60.2631C77.2791 60.967 75.7655 60.5949 75.0616 59.4318C74.3576 58.2687 74.7298 56.7551 75.8929 56.0512L85.2967 50.3594C86.0818 49.8842 87.0661 49.8852 87.8503 50.362L91.2323 52.4183L102.612 45.2868C103.455 44.7587 104.532 44.7895 105.343 45.365L111.487 49.7235L128.216 37.3076C129.308 36.4974 130.85 36.7256 131.66 37.8173Z"
            fill="#F87D6C"
          />
          <path
            d="M131.419 75.3886H108.253C107.869 75.3886 107.528 75.1014 107.528 74.6911V72.8039C107.528 72.4346 107.826 72.1064 108.253 72.1064H131.419C131.803 72.1064 132.144 72.3936 132.144 72.8039V74.6911C132.102 75.1014 131.803 75.3886 131.419 75.3886Z"
            fill="#FBA99D"
          />
          <path
            d="M124.038 80.3119H108.249C107.867 80.3119 107.528 80.0966 107.528 79.7888V78.3734C107.528 78.0964 107.825 77.8503 108.249 77.8503H124.038C124.42 77.8503 124.759 78.0657 124.759 78.3734V79.7888C124.759 80.0658 124.42 80.3119 124.038 80.3119Z"
            fill="#FBA99D"
          />
          <path
            d="M131.419 90.1586H108.253C107.869 90.1586 107.528 89.7996 107.528 89.2868V86.9277C107.528 86.4661 107.826 86.0558 108.253 86.0558H131.419C131.803 86.0558 132.144 86.4148 132.144 86.9277V89.2868C132.144 89.7996 131.803 90.1586 131.419 90.1586Z"
            fill="#FBA99D"
          />
          <path
            d="M124.038 95.9026H108.249C107.867 95.9026 107.528 95.6154 107.528 95.2051V93.3178C107.528 92.9486 107.825 92.6204 108.249 92.6204H124.038C124.42 92.6204 124.759 92.9076 124.759 93.3178V95.2051C124.759 95.5744 124.42 95.9026 124.038 95.9026Z"
            fill="#FBA99D"
          />
          <path
            d="M131.419 104.108H108.253C107.869 104.108 107.528 103.821 107.528 103.411V101.523C107.528 101.154 107.826 100.826 108.253 100.826H131.419C131.803 100.826 132.144 101.113 132.144 101.523V103.411C132.144 103.821 131.803 104.108 131.419 104.108Z"
            fill="#FBA99D"
          />
          <path
            d="M124.038 109.852H108.249C107.867 109.852 107.528 109.493 107.528 108.98V106.621C107.528 106.159 107.825 105.749 108.249 105.749H124.038C124.42 105.749 124.759 106.108 124.759 106.621V108.98C124.759 109.493 124.42 109.852 124.038 109.852Z"
            fill="#FBA99D"
          />
          <path
            d="M99.0995 89.338H77.3888C75.8845 89.338 74.7055 88.1072 74.7055 86.6302V74.8142C74.7055 73.2962 75.9252 72.1064 77.3888 72.1064H99.0995C100.604 72.1064 101.783 73.3372 101.783 74.8142V86.6302C101.823 88.1072 100.604 89.338 99.0995 89.338Z"
            fill="#FBA99D"
          />
          <path
            d="M99.0995 109.852H77.3888C75.8845 109.852 74.7055 108.621 74.7055 107.144V95.3282C74.7055 93.8102 75.9252 92.6204 77.3888 92.6204H99.0995C100.604 92.6204 101.783 93.8512 101.783 95.3282V107.144C101.823 108.662 100.604 109.852 99.0995 109.852Z"
            fill="#FBA99D"
          />
          <g filter="url(#filter2_d_393_1071)">
            <path
              d="M178.916 62.43V117.696C178.916 120.498 176.624 122.739 173.822 122.739H131.494C128.693 122.739 126.4 120.447 126.4 117.696V62.43C126.4 59.6285 128.693 57.3364 131.494 57.3364H173.873C176.624 57.3364 178.916 59.6285 178.916 62.43Z"
              fill="#FEFAFB"
            />
          </g>
          <path
            d="M178.916 62.43V76.2526C178.916 76.8048 178.468 77.2526 177.916 77.2526H127.4C126.848 77.2526 126.4 76.8048 126.4 76.2526V62.43C126.4 59.6285 128.693 57.3364 131.494 57.3364H173.873C176.624 57.3364 178.916 59.6285 178.916 62.43Z"
            fill="#F87D6C"
          />
          <path
            d="M137.963 87.0833H132.309C131.494 87.0833 130.832 86.4211 130.832 85.6061V83.0593C130.832 82.2443 131.494 81.5822 132.309 81.5822H137.963C138.778 81.5822 139.44 82.2443 139.44 83.0593V85.6061C139.44 86.4211 138.778 87.0833 137.963 87.0833Z"
            fill="#FBA99D"
          />
          <path
            d="M149.678 87.0833H144.024C143.209 87.0833 142.547 86.4211 142.547 85.6061V83.0593C142.547 82.2443 143.209 81.5822 144.024 81.5822H149.678C150.493 81.5822 151.156 82.2443 151.156 83.0593V85.6061C151.156 86.4211 150.493 87.0833 149.678 87.0833Z"
            fill="#FBA99D"
          />
          <path
            d="M161.445 87.0833H155.74C154.925 87.0833 154.263 86.4211 154.263 85.6061V83.0593C154.263 82.2443 154.925 81.5822 155.74 81.5822H161.394C162.209 81.5822 162.871 82.2443 162.871 83.0593V85.6061C162.871 86.4211 162.209 87.0833 161.445 87.0833Z"
            fill="#FBA99D"
          />
          <path
            d="M173.16 87.0833H167.506C166.691 87.0833 166.029 86.4211 166.029 85.6061V83.0593C166.029 82.2443 166.691 81.5822 167.506 81.5822H173.16C173.975 81.5822 174.637 82.2443 174.637 83.0593V85.6061C174.586 86.4211 173.975 87.0833 173.16 87.0833Z"
            fill="#FBA99D"
          />
          <path
            d="M137.963 96.3027H132.309C131.494 96.3027 130.832 95.6405 130.832 94.8256V92.2787C130.832 91.4637 131.494 90.8016 132.309 90.8016H137.963C138.778 90.8016 139.44 91.4637 139.44 92.2787V94.8256C139.44 95.6405 138.778 96.3027 137.963 96.3027Z"
            fill="#FBA99D"
          />
          <path
            d="M149.678 96.3027H144.024C143.209 96.3027 142.547 95.6405 142.547 94.8256V92.2787C142.547 91.4637 143.209 90.8016 144.024 90.8016H149.678C150.493 90.8016 151.156 91.4637 151.156 92.2787V94.8256C151.156 95.6405 150.493 96.3027 149.678 96.3027Z"
            fill="#FBA99D"
          />
          <path
            d="M161.445 96.3027H155.74C154.925 96.3027 154.263 95.6405 154.263 94.8256V92.2787C154.263 91.4637 154.925 90.8016 155.74 90.8016H161.394C162.209 90.8016 162.871 91.4637 162.871 92.2787V94.8256C162.871 95.6405 162.209 96.3027 161.445 96.3027Z"
            fill="#FBA99D"
          />
          <path
            d="M173.16 96.3027H167.506C166.691 96.3027 166.029 95.6405 166.029 94.8256V92.2787C166.029 91.4637 166.691 90.8016 167.506 90.8016H173.16C173.975 90.8016 174.637 91.4637 174.637 92.2787V94.8256C174.586 95.6405 173.975 96.3027 173.16 96.3027Z"
            fill="#FBA99D"
          />
          <path
            d="M137.963 105.471H132.309C131.494 105.471 130.832 104.809 130.832 103.994V101.447C130.832 100.632 131.494 99.9702 132.309 99.9702H137.963C138.778 99.9702 139.44 100.632 139.44 101.447V103.994C139.44 104.86 138.778 105.471 137.963 105.471Z"
            fill="#FBA99D"
          />
          <path
            d="M149.678 105.471H144.024C143.209 105.471 142.547 104.809 142.547 103.994V101.447C142.547 100.632 143.209 99.9702 144.024 99.9702H149.678C150.493 99.9702 151.156 100.632 151.156 101.447V103.994C151.156 104.86 150.493 105.471 149.678 105.471Z"
            fill="#FBA99D"
          />
          <path
            d="M161.445 105.471H155.74C154.925 105.471 154.263 104.809 154.263 103.994V101.447C154.263 100.632 154.925 99.9702 155.74 99.9702H161.394C162.209 99.9702 162.871 100.632 162.871 101.447V103.994C162.871 104.86 162.209 105.471 161.445 105.471Z"
            fill="#FBA99D"
          />
          <path
            d="M174.586 101.498V113.265C174.586 114.08 173.924 114.742 173.16 114.742H167.506C166.691 114.742 166.029 114.08 166.029 113.265V101.498C166.029 100.683 166.691 100.021 167.506 100.021H173.16C173.975 100.021 174.586 100.683 174.586 101.498Z"
            fill="#FBA99D"
          />
          <path
            d="M137.963 114.691H132.309C131.494 114.691 130.832 114.029 130.832 113.214V110.667C130.832 109.852 131.494 109.19 132.309 109.19H137.963C138.778 109.19 139.44 109.852 139.44 110.667V113.214C139.44 114.08 138.778 114.691 137.963 114.691Z"
            fill="#FBA99D"
          />
          <path
            d="M149.678 114.691H144.024C143.209 114.691 142.547 114.029 142.547 113.214V110.667C142.547 109.852 143.209 109.19 144.024 109.19H149.678C150.493 109.19 151.156 109.852 151.156 110.667V113.214C151.156 114.08 150.493 114.691 149.678 114.691Z"
            fill="#FBA99D"
          />
          <path
            d="M161.445 114.691H155.74C154.925 114.691 154.263 114.029 154.263 113.214V110.667C154.263 109.852 154.925 109.19 155.74 109.19H161.394C162.209 109.19 162.871 109.852 162.871 110.667V113.214C162.871 114.08 162.209 114.691 161.445 114.691Z"
            fill="#FBA99D"
          />
          <path
            d="M172.192 72.4644H133.277C131.952 72.4644 130.832 71.3948 130.832 70.0195V64.2636C130.832 62.9393 131.902 61.8187 133.277 61.8187H172.192C173.517 61.8187 174.637 62.8884 174.637 64.2636V70.0195C174.586 71.3438 173.517 72.4644 172.192 72.4644Z"
            fill="#FEFAFB"
          />
          <path
            d="M155.536 68.2878H170.104C170.817 68.2878 171.377 67.7275 171.377 67.0143C171.377 66.3012 170.817 65.7409 170.104 65.7409H155.536C154.823 65.7409 154.263 66.3012 154.263 67.0143C154.314 67.7275 154.874 68.2878 155.536 68.2878Z"
            fill="#FBA99D"
          />
          <g filter="url(#filter3_d_393_1071)">
            <path
              d="M41.0436 67.2753C41.3389 66.3378 40.6487 65.3129 39.6879 65.1473C31.934 63.4515 24.2809 67.8683 22.8551 75.6121C21.6818 81.9692 25.3281 88.8125 31.5955 91.7197C39.8608 95.5927 49.1222 91.9174 51.6072 84.0267C51.7549 83.5579 51.8779 83.1673 51.9427 82.6693C52.155 81.7025 51.4403 80.7556 50.372 80.6389L38.587 79.1695C37.9576 79.1209 37.5251 78.4473 37.722 77.8223L41.0436 67.2753V67.2753Z"
              fill="#F87D6C"
            />
          </g>
          <path
            d="M42.9754 66.1738C42.2538 65.8322 41.4182 66.1451 41.1967 66.8486C40.8028 68.0992 40.212 69.9751 40.212 69.9751C40.212 69.9751 40.212 69.9751 40.212 69.9751L37.7997 77.6351C37.5781 78.3386 38.0937 79.0417 38.8891 79.1489L50.8452 80.6776C51.6407 80.7847 52.2767 80.2276 52.2249 79.5145C52.0462 75.1087 49.8557 70.948 46.2361 68.1067C45.1738 67.3842 44.1115 66.6618 42.9754 66.1738Z"
            fill="#FBA99D"
          />
          <g filter="url(#filter4_d_393_1071)">
            <path
              d="M81.1595 42.5664H42.4279C40.6101 42.5664 39.0721 41.0283 39.0721 39.2106V14.7412C39.0721 12.9234 40.6101 11.3853 42.4279 11.3853H81.1595C82.9773 11.3853 84.5153 12.9234 84.5153 14.7412V39.3504C84.5153 41.1682 82.9773 42.5664 81.1595 42.5664Z"
              fill="url(#paint1_linear_393_1071)"
            />
          </g>
          <path
            d="M49.2793 36.4142H46.343C45.6439 36.4142 45.2244 35.8549 45.2244 35.2956V32.7788C45.2244 32.0796 45.7837 31.6602 46.343 31.6602H49.2793C49.9785 31.6602 50.3979 32.2195 50.3979 32.7788V35.2956C50.3979 35.8549 49.9785 36.4142 49.2793 36.4142Z"
            fill="#F87D6C"
          />
          <path
            d="M58.6476 36.4141H55.7113C55.0122 36.4141 54.5927 35.8548 54.5927 35.2955V29.4228C54.5927 28.7237 55.152 28.3042 55.7113 28.3042H58.6476C59.3467 28.3042 59.7662 28.8635 59.7662 29.4228V35.2955C59.7662 35.8548 59.3467 36.4141 58.6476 36.4141Z"
            fill="#F87D6C"
          />
          <path
            d="M68.0159 36.4141H65.0796C64.3804 36.4141 63.961 35.8548 63.961 35.2955V23.4104C63.961 22.7112 64.5203 22.2917 65.0796 22.2917H68.0159C68.715 22.2917 69.1345 22.8511 69.1345 23.4104V35.1557C69.1345 35.8548 68.5752 36.4141 68.0159 36.4141Z"
            fill="#F87D6C"
          />
          <path
            d="M77.3842 36.4141H74.4479C73.7487 36.4141 73.3293 35.8548 73.3293 35.2955V18.7961C73.3293 18.097 73.8886 17.6775 74.4479 17.6775H77.3842C78.0833 17.6775 78.5028 18.2368 78.5028 18.7961V35.2955C78.5028 35.8548 77.9435 36.4141 77.3842 36.4141Z"
            fill="#F87D6C"
          />
          <defs>
            <filter
              id="filter0_d_393_1071"
              x="42.5409"
              y="3.36792"
              width="121.689"
              height="155.895"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_393_1071"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_393_1071"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_393_1071"
              x="104.032"
              y="4.04544"
              width="61.1976"
              height="60.2539"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_393_1071"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_393_1071"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_393_1071"
              x="104.4"
              y="46.3364"
              width="96.5155"
              height="109.402"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_393_1071"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_393_1071"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_393_1071"
              x="0.636719"
              y="53.7866"
              width="73.3433"
              height="72.4852"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_393_1071"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_393_1071"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d_393_1071"
              x="17.0721"
              y="0.385345"
              width="89.4433"
              height="75.1811"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_393_1071"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_393_1071"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_393_1071"
              x1="103.36"
              y1="11.7797"
              x2="103.36"
              y2="127.469"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_393_1071"
              x1="61.7789"
              y1="10.6641"
              x2="61.7789"
              y2="42.9026"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      );

    case "notfound":
      return (
        <svg
          width="400"
          height="389"
          viewBox="0 0 400 389"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M151.735 43.0079C67.9341 66.2083 18.8075 152.95 42.0078 236.751C65.2081 320.552 151.95 369.679 235.751 346.478C319.552 323.278 368.678 236.536 345.478 152.735C322.278 68.9342 235.536 19.8076 151.735 43.0079Z"
            fill="#FBA99D"
          />
          <path
            d="M248.979 88.7872L251.967 87.2789C252.125 87.2351 252.398 86.9894 252.31 86.6731L252.067 83.3348C252.094 82.8165 252.323 82.4126 252.797 82.2813C253.272 82.1499 253.834 82.3349 253.965 82.8094L255.473 85.7974C255.517 85.9555 255.763 86.228 256.079 86.1405L259.417 85.8974C259.936 85.9242 260.339 86.1529 260.471 86.6274C260.602 87.1019 260.417 87.6639 259.943 87.7952L256.955 89.3036C256.797 89.3474 256.524 89.5931 256.612 89.9094L256.855 93.2477C256.828 93.7659 256.599 94.1698 256.125 94.3012C255.65 94.4325 255.088 94.2476 254.957 93.7731L253.449 90.7851C253.405 90.627 253.159 90.3545 252.843 90.442L249.504 90.6851C248.986 90.6583 248.582 90.4296 248.451 89.9551C248.363 89.6388 248.548 89.0767 248.979 88.7872Z"
            fill="#F87D6C"
          />
          <path
            d="M105.519 105.969L108.368 104.531C108.518 104.49 108.778 104.255 108.695 103.954L108.463 100.771C108.489 100.277 108.707 99.8923 109.159 99.7671C109.611 99.6418 110.147 99.8182 110.272 100.27L111.71 103.119C111.752 103.27 111.986 103.53 112.288 103.446L115.47 103.214C115.964 103.24 116.349 103.458 116.475 103.91C116.6 104.363 116.423 104.898 115.971 105.024L113.123 106.462C112.972 106.503 112.712 106.738 112.795 107.039L113.027 110.222C113.002 110.716 112.784 111.101 112.331 111.226C111.879 111.351 111.343 111.175 111.218 110.723L109.78 107.874C109.738 107.723 109.504 107.463 109.202 107.547L106.02 107.779C105.526 107.753 105.141 107.535 105.016 107.083C104.89 106.63 105.067 106.095 105.519 105.969Z"
            fill="#F87D6C"
          />
          <path
            d="M207.564 137.161L209.865 136C209.986 135.966 210.196 135.777 210.129 135.533L209.942 132.963C209.962 132.564 210.138 132.253 210.504 132.152C210.869 132.051 211.302 132.193 211.403 132.559L212.564 134.859C212.598 134.981 212.787 135.191 213.031 135.123L215.601 134.936C216 134.957 216.311 135.133 216.412 135.498C216.514 135.864 216.371 136.296 216.006 136.398L213.705 137.559C213.583 137.593 213.373 137.782 213.441 138.025L213.628 140.596C213.607 140.995 213.431 141.306 213.066 141.407C212.701 141.508 212.268 141.366 212.167 141L211.005 138.7C210.972 138.578 210.782 138.368 210.539 138.436L207.968 138.623C207.569 138.602 207.258 138.426 207.157 138.061C206.968 137.851 207.232 137.384 207.564 137.161Z"
            fill="#F87D6C"
          />
          <path
            d="M116.923 299.194L121.054 297.109C121.272 297.049 121.649 296.709 121.528 296.272L121.192 291.657C121.229 290.941 121.545 290.382 122.201 290.201C122.857 290.019 123.634 290.275 123.816 290.931L125.901 295.061C125.961 295.28 126.301 295.657 126.738 295.535L131.353 295.199C132.069 295.236 132.628 295.553 132.809 296.209C132.991 296.864 132.735 297.641 132.079 297.823L127.949 299.908C127.73 299.969 127.353 300.308 127.474 300.746L127.81 305.36C127.773 306.077 127.457 306.635 126.801 306.817C126.145 306.998 125.368 306.743 125.187 306.087L123.102 301.956C123.041 301.738 122.702 301.361 122.264 301.482L117.65 301.818C116.933 301.781 116.375 301.465 116.193 300.809C116.012 300.153 116.328 299.595 116.923 299.194Z"
            fill="#F87D6C"
          />
          <path
            d="M326.488 189.895L328.652 186.649C328.787 186.514 328.923 186.108 328.652 185.837L326.488 182.591C326.218 182.05 326.218 181.509 326.623 181.104C327.029 180.698 327.705 180.563 328.111 180.968L331.357 183.132C331.492 183.268 331.898 183.403 332.169 183.132L335.415 180.968C335.956 180.698 336.497 180.698 336.902 181.104C337.308 181.509 337.443 182.186 337.038 182.591L334.874 185.837C334.738 185.973 334.603 186.378 334.874 186.649L337.038 189.895C337.308 190.436 337.308 190.977 336.902 191.383C336.497 191.789 335.82 191.924 335.415 191.518L332.169 189.354C332.033 189.219 331.628 189.084 331.357 189.354L328.111 191.518C327.57 191.789 327.029 191.789 326.623 191.383C326.218 190.977 326.082 190.301 326.488 189.895Z"
            fill="#F87D6C"
          />
          <path
            d="M117.308 166.741L120.077 161.187C120.264 160.946 120.397 160.279 119.916 159.906L115.854 155.213C115.319 154.413 115.212 153.559 115.771 152.838C116.331 152.117 117.371 151.769 118.093 152.329L123.647 155.098C123.887 155.284 124.554 155.417 124.927 154.936L129.62 150.874C130.42 150.34 131.274 150.232 131.995 150.792C132.716 151.351 133.064 152.392 132.505 153.113L129.735 158.667C129.549 158.907 129.416 159.575 129.897 159.948L133.959 164.64C134.493 165.44 134.601 166.294 134.041 167.015C133.482 167.737 132.441 168.085 131.72 167.525L126.166 164.756C125.926 164.569 125.258 164.437 124.885 164.917L120.193 168.979C119.393 169.514 118.539 169.622 117.818 169.062C117.097 168.502 116.749 167.462 117.308 166.741Z"
            fill="#F87D6C"
          />
          <path
            d="M172.467 330.801L176.347 328.842C176.552 328.785 176.906 328.466 176.792 328.056L176.477 323.721C176.512 323.048 176.809 322.524 177.425 322.353C178.041 322.183 178.771 322.423 178.941 323.039L180.9 326.919C180.956 327.124 181.275 327.478 181.686 327.364L186.021 327.049C186.693 327.083 187.218 327.38 187.388 327.996C187.559 328.612 187.319 329.342 186.703 329.513L182.823 331.471C182.618 331.528 182.264 331.847 182.378 332.258L182.693 336.592C182.659 337.265 182.361 337.79 181.745 337.96C181.129 338.131 180.4 337.891 180.229 337.275L178.271 333.395C178.214 333.19 177.895 332.836 177.484 332.949L173.15 333.265C172.477 333.23 171.952 332.933 171.782 332.317C171.611 331.701 171.851 330.971 172.467 330.801Z"
            fill="#F87D6C"
          />
          <path
            d="M97.8891 231.555L100.588 226.77C100.764 226.565 100.911 225.981 100.503 225.63L97.123 221.298C96.6863 220.567 96.6296 219.807 97.1569 219.194C97.6842 218.582 98.6197 218.321 99.2321 218.848L104.018 221.547C104.222 221.723 104.806 221.87 105.158 221.462L109.489 218.082C110.221 217.646 110.981 217.589 111.593 218.116C112.205 218.643 112.466 219.579 111.939 220.191L109.24 224.977C109.065 225.181 108.917 225.765 109.325 226.117L112.705 230.449C113.142 231.18 113.199 231.94 112.671 232.552C112.144 233.165 111.208 233.426 110.596 232.898L105.81 230.2C105.606 230.024 105.022 229.876 104.671 230.285L100.339 233.664C99.6073 234.101 98.8475 234.158 98.2351 233.631C97.6227 233.103 97.3618 232.168 97.8891 231.555Z"
            fill="#F87D6C"
          />
          <path
            d="M165.583 82.2682L170.648 79.8218C171.133 79.6875 171.241 79.1355 171.174 78.8931L170.667 73.2909C170.708 72.4965 171.234 71.5678 171.961 71.3664C172.689 71.165 173.55 71.4486 174.061 72.3512L176.507 77.4164C176.642 77.9013 177.194 78.0095 177.436 77.9424L183.038 77.4355C183.833 77.4766 184.761 78.0026 184.963 78.7299C185.164 79.4572 184.881 80.3188 183.978 80.8297L178.913 83.2761C178.428 83.4104 178.32 83.9624 178.387 84.2048L178.894 89.807C178.853 90.6015 178.327 91.5302 177.599 91.7315C176.872 91.9329 176.01 91.6493 175.5 90.7467L173.053 85.6815C172.919 85.1966 172.367 85.0884 172.124 85.1555L166.522 85.6624C165.728 85.6213 164.799 85.0953 164.598 84.368C164.329 83.3982 164.613 82.5367 165.583 82.2682Z"
            fill="#F87D6C"
          />
          <path
            d="M283.921 136.869L286.465 135.64C286.709 135.572 286.763 135.295 286.73 135.173L286.475 132.359C286.496 131.96 286.76 131.494 287.125 131.393C287.49 131.292 287.923 131.434 288.18 131.887L289.409 134.432C289.476 134.675 289.753 134.73 289.875 134.696L292.689 134.441C293.088 134.462 293.555 134.726 293.656 135.091C293.757 135.457 293.614 135.889 293.161 136.146L290.617 137.375C290.373 137.442 290.319 137.72 290.353 137.841L290.607 140.655C290.587 141.054 290.322 141.521 289.957 141.622C289.592 141.723 289.159 141.581 288.902 141.127L287.674 138.583C287.606 138.34 287.329 138.285 287.207 138.319L284.393 138.574C283.994 138.553 283.528 138.289 283.426 137.923C283.325 137.558 283.589 137.092 283.921 136.869Z"
            fill="#F87D6C"
          />
          <path
            d="M261.313 294.899L267.046 292.13C267.595 291.978 267.718 291.353 267.642 291.079L267.068 284.737C267.115 283.838 267.71 282.787 268.533 282.559C269.356 282.331 270.332 282.652 270.91 283.674L273.679 289.407C273.831 289.956 274.456 290.078 274.73 290.002L281.072 289.429C281.971 289.475 283.022 290.071 283.25 290.894C283.478 291.717 283.157 292.692 282.135 293.271L276.402 296.04C275.853 296.192 275.731 296.817 275.806 297.091L276.38 303.432C276.334 304.332 275.738 305.383 274.915 305.611C274.092 305.839 273.117 305.518 272.538 304.496L269.769 298.762C269.617 298.214 268.992 298.091 268.718 298.167L262.377 298.741C261.477 298.694 260.426 298.099 260.198 297.276C259.97 296.452 260.291 295.477 261.313 294.899Z"
            fill="#F87D6C"
          />
          <path
            d="M288.18 236.21L292.301 234.22C292.695 234.111 292.783 233.662 292.729 233.465L292.316 228.907C292.35 228.261 292.778 227.506 293.369 227.342C293.961 227.178 294.662 227.409 295.078 228.143L297.068 232.263C297.177 232.658 297.626 232.746 297.823 232.691L302.381 232.279C303.027 232.312 303.782 232.74 303.946 233.332C304.11 233.924 303.879 234.624 303.145 235.04L299.024 237.03C298.63 237.139 298.542 237.588 298.597 237.786L299.009 242.343C298.975 242.989 298.548 243.745 297.956 243.909C297.364 244.072 296.663 243.842 296.248 243.107L294.258 238.987C294.148 238.592 293.699 238.504 293.502 238.559L288.945 238.971C288.299 238.938 287.543 238.51 287.379 237.918C287.215 237.327 287.643 236.571 288.18 236.21Z"
            fill="#F87D6C"
          />
          <path
            d="M58.9803 175.858L65.0907 175.305C65.6195 175.159 66.0304 175.614 66.1036 175.879L68.7719 181.403C69.256 182.123 70.2689 182.697 71.0622 182.478C71.8555 182.258 72.5024 181.509 72.474 180.378L71.9212 174.268C71.7747 173.739 72.2304 173.328 72.4948 173.255L78.0196 170.587C78.7396 170.103 79.3133 169.09 79.0937 168.297C78.874 167.503 78.1256 166.856 76.9946 166.885L70.8842 167.438C70.3553 167.584 69.9445 167.128 69.8713 166.864L67.203 161.339C66.7189 160.619 65.706 160.045 64.9127 160.265C64.1194 160.485 63.4725 161.233 63.5009 162.364L64.0537 168.475C64.2001 169.003 63.7445 169.414 63.4801 169.487L57.9553 172.156C57.2352 172.64 56.6616 173.653 56.8812 174.446C57.174 175.504 58.1869 176.078 58.9803 175.858Z"
            fill="#F87D6C"
          />
          <path
            d="M26.3146 297.466L34.9542 296.684C35.702 296.477 36.2829 297.121 36.3864 297.495L40.1592 305.307C40.8436 306.325 42.2758 307.136 43.3974 306.825C44.5191 306.515 45.4337 305.457 45.3936 303.858L44.6119 295.218C44.4049 294.47 45.0491 293.889 45.423 293.786L53.2345 290.013C54.2527 289.329 55.0638 287.896 54.7532 286.775C54.4427 285.653 53.3844 284.738 51.7854 284.779L43.1458 285.56C42.398 285.767 41.8171 285.123 41.7136 284.749L37.9408 276.938C37.2564 275.92 35.8242 275.108 34.7025 275.419C33.5809 275.729 32.6663 276.788 32.7064 278.387L33.4881 287.026C33.6951 287.774 33.0509 288.355 32.677 288.459L24.8655 292.231C23.8473 292.916 23.0362 294.348 23.3468 295.47C23.7608 296.965 25.193 297.776 26.3146 297.466Z"
            fill="#FBA99D"
          />
          <path
            d="M40.6603 78.1494L48.6263 77.4287C49.3158 77.2378 49.8513 77.8319 49.9468 78.1766L53.4254 85.379C54.0565 86.3177 55.377 87.0656 56.4112 86.7793C57.4454 86.493 58.2887 85.5172 58.2517 84.0428L57.531 76.0769C57.3401 75.3874 57.9341 74.8518 58.2788 74.7564L65.4812 71.2778C66.42 70.6467 67.1678 69.3262 66.8815 68.292C66.5952 67.2578 65.6194 66.4144 64.1451 66.4515L56.1791 67.1722C55.4897 67.3631 54.9541 66.7691 54.8586 66.4243L51.38 59.222C50.749 58.2832 49.4284 57.5353 48.3942 57.8216C47.36 58.1079 46.5167 59.0837 46.5538 60.5581L47.2745 68.524C47.4653 69.2135 46.8714 69.7491 46.5266 69.8445L39.3242 73.3231C38.3854 73.9542 37.6376 75.2747 37.9239 76.3089C38.3057 77.6879 39.6261 78.4358 40.6603 78.1494Z"
            fill="#FBA99D"
          />
          <path
            d="M356.233 227.352L367.091 231.221C368.109 231.388 368.451 232.489 368.367 232.998L368.567 244.523C368.826 246.133 370.102 247.909 371.628 248.159C373.154 248.409 374.847 247.642 375.689 245.691L379.559 234.833C379.726 233.815 380.827 233.473 381.336 233.557L392.861 233.357C394.471 233.098 396.247 231.822 396.497 230.296C396.747 228.77 395.98 227.077 394.029 226.235L383.17 222.365C382.153 222.198 381.811 221.097 381.895 220.588L381.694 209.063C381.436 207.454 380.16 205.677 378.634 205.427C377.108 205.177 375.415 205.944 374.572 207.895L370.703 218.754C370.536 219.771 369.435 220.113 368.926 220.03L357.401 220.229C355.791 220.488 354.015 221.764 353.765 223.29C353.431 225.325 354.707 227.101 356.233 227.352Z"
            fill="#FBA99D"
          />
          <path
            d="M128.505 117.749C127.496 118.028 126.904 119.072 127.184 120.081C127.463 121.09 128.507 121.681 129.516 121.402C130.525 121.123 131.116 120.078 130.837 119.07C130.558 118.061 129.514 117.469 128.505 117.749Z"
            fill="#F87D6C"
          />
          <path
            d="M193.741 165.374C192.733 165.654 192.141 166.698 192.421 167.707C192.7 168.716 193.744 169.307 194.753 169.028C195.762 168.748 196.353 167.704 196.074 166.695C195.795 165.686 194.75 165.095 193.741 165.374Z"
            fill="#F87D6C"
          />
          <path
            d="M236.119 88.5433C235.585 89.4435 235.881 90.6063 236.782 91.1407C237.682 91.6751 238.845 91.3786 239.379 90.4785C239.913 89.5783 239.617 88.4155 238.717 87.8811C237.817 87.3467 236.654 87.6432 236.119 88.5433Z"
            fill="#F87D6C"
          />
          <path
            d="M174.964 52.2179C174.43 53.118 174.726 54.2809 175.626 54.8153C176.526 55.3496 177.689 55.0532 178.224 54.153C178.758 53.2529 178.461 52.0901 177.561 51.5557C176.661 51.0213 175.498 51.3178 174.964 52.2179Z"
            fill="#F87D6C"
          />
          <path
            d="M65.8671 135.661C65.3327 136.561 65.6292 137.724 66.5293 138.258C67.4294 138.792 68.5923 138.496 69.1267 137.596C69.661 136.696 69.3645 135.533 68.4644 134.998C67.5643 134.464 66.4015 134.76 65.8671 135.661Z"
            fill="#F87D6C"
          />
          <path
            d="M73.8092 210.194C73.2748 211.094 73.5713 212.257 74.4714 212.791C75.3715 213.326 76.5344 213.029 77.0687 212.129C77.6031 211.229 77.3066 210.066 76.4065 209.532C75.5064 208.997 74.3435 209.294 73.8092 210.194Z"
            fill="#F87D6C"
          />
          <path
            d="M94.4845 284.687C93.9501 285.588 94.2466 286.75 95.1467 287.285C96.0468 287.819 97.2097 287.523 97.744 286.623C98.2784 285.722 97.9819 284.56 97.0818 284.025C96.1817 283.491 95.0188 283.787 94.4845 284.687Z"
            fill="#F87D6C"
          />
          <path
            d="M144.483 322.755C143.948 323.655 144.245 324.818 145.145 325.353C146.045 325.887 147.208 325.59 147.742 324.69C148.277 323.79 147.98 322.627 147.08 322.093C146.18 321.559 145.017 321.855 144.483 322.755Z"
            fill="#F87D6C"
          />
          <path
            d="M235.948 323.366C235.414 324.266 235.711 325.429 236.611 325.963C237.511 326.498 238.674 326.201 239.208 325.301C239.742 324.401 239.446 323.238 238.546 322.704C237.646 322.169 236.483 322.466 235.948 323.366Z"
            fill="#F87D6C"
          />
          <path
            d="M299.588 266.585C299.053 267.485 299.35 268.648 300.25 269.182C301.15 269.717 302.313 269.42 302.847 268.52C303.382 267.62 303.085 266.457 302.185 265.923C301.285 265.388 300.122 265.685 299.588 266.585Z"
            fill="#F87D6C"
          />
          <path
            d="M333.505 205C332.971 205.9 333.267 207.063 334.167 207.598C335.068 208.132 336.23 207.836 336.765 206.935C337.299 206.035 337.003 204.873 336.103 204.338C335.202 203.804 334.04 204.1 333.505 205Z"
            fill="#F87D6C"
          />
          <path
            d="M295.206 110.44C294.672 111.34 294.968 112.503 295.868 113.038C296.769 113.572 297.931 113.275 298.466 112.375C299 111.475 298.704 110.312 297.804 109.778C296.903 109.244 295.741 109.54 295.206 110.44Z"
            fill="#F87D6C"
          />
          <path
            d="M267.884 192.546L284.428 179.964L278.543 172.152L261.998 184.734L267.884 192.546Z"
            fill="#F6523B"
          />
          <path
            d="M376.523 143.293L338.841 93.2464L262.118 151.631L299.8 201.678L376.523 143.293Z"
            fill="#FEFAFB"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M319.742 187.348L281.041 136.22"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M338.668 173.022L299.967 121.894"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M357.594 158.696L318.893 107.568"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M350.74 110.312L275.036 167.616"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M363.661 127.382L287.958 184.686"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M286.58 257.589C280.573 262.136 272.035 260.946 267.478 254.926L259.925 244.948C259.551 244.453 259.65 243.729 260.144 243.356L280.14 228.22C280.633 227.846 281.357 227.947 281.731 228.442L289.284 238.42C293.759 244.502 292.504 253.105 286.58 257.589Z"
            fill="#FAEDEE"
          />
          <path
            d="M239.294 155.108L200.702 184.32L253.76 254.415L253.822 254.497C253.822 254.497 253.822 254.497 253.884 254.58C256.089 257.321 266.414 253.008 277.029 244.973C287.644 236.938 294.517 228.234 292.559 225.305C292.559 225.305 292.559 225.305 292.497 225.223L292.434 225.14L239.377 155.046L239.294 155.108Z"
            fill="#FEFAFB"
          />
          <path
            d="M258.333 180.26L273.064 199.721L273.126 199.804L273.189 199.886C275.229 202.752 268.274 211.519 257.659 219.554C247.044 227.589 236.801 231.84 234.514 229.161L234.452 229.078L234.389 228.996L219.658 209.534C221.862 212.276 232.188 207.962 242.803 199.927C253.418 191.892 260.31 183.043 258.333 180.26Z"
            fill="#F6523B"
          />
          <path
            d="M240.695 122.697C250.706 135.984 242.874 158.914 223.172 173.897C203.469 188.881 179.295 190.292 169.284 177.005C169.224 176.924 169.163 176.844 169.102 176.763C169.042 176.683 168.92 176.521 168.86 176.441C165.658 171.149 178.54 155.272 197.91 140.541C217.28 125.81 236.048 117.744 240.187 122.197C240.248 122.277 240.369 122.438 240.43 122.519C240.513 122.455 240.574 122.536 240.695 122.697Z"
            fill="#FAEDEE"
          />
          <path
            d="M240.083 122.247L240.143 122.327C241.269 123.992 240.656 126.979 238.559 130.588C234.343 137.949 224.328 148.329 211.327 158.204C198.327 168.079 185.57 174.997 177.448 177.007C173.417 178.053 170.387 177.834 169.096 176.295L169.035 176.215C168.975 176.135 168.914 176.054 168.854 175.974C165.664 170.71 178.497 154.913 197.79 140.258C217.084 125.602 235.778 117.577 239.901 122.007C239.962 122.087 240.022 122.167 240.083 122.247Z"
            fill="#FAEDEE"
          />
          <path
            d="M238.534 130.627L238.594 130.707C234.383 138.056 224.379 148.419 211.394 158.278C198.408 168.137 185.666 175.043 177.553 177.051L177.493 176.971C177.433 176.891 177.372 176.811 177.372 176.811C174.647 172.337 185.676 158.679 202.219 146.12C218.843 133.498 234.861 126.621 238.413 130.467C238.413 130.467 238.473 130.547 238.534 130.627Z"
            fill="#F6523B"
          />
          <path
            d="M208.582 146.673C208.868 147.228 207.486 148.922 205.458 150.463C203.346 152.068 201.348 152.944 200.916 152.371L187.106 130.701L191.077 127.683L208.459 146.51L208.582 146.673Z"
            fill="#F6523B"
          />
          <path
            d="M190.749 131.012C193.337 129.043 193.903 125.414 192.015 122.906C190.127 120.398 186.499 119.961 183.911 121.93C181.324 123.899 180.757 127.528 182.646 130.036C184.534 132.544 188.162 132.981 190.749 131.012Z"
            fill="#F6523B"
          />
          <path
            d="M152.669 313.042L114.987 262.995L191.71 204.61L229.392 254.657L152.669 313.042Z"
            fill="#FEFAFB"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M210.466 270.064L171.765 218.936"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M191.54 284.389L152.839 233.261"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M172.614 298.715L133.913 247.588"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M127.908 278.984L203.612 221.681"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M140.83 296.054L216.534 238.75"
            stroke="#F6523B"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M223.59 213.82L207.045 226.403L212.931 234.215L229.475 221.632L223.59 213.82Z"
            fill="#F6523B"
          />
          <path
            d="M105.476 75.4874C104.831 79.7905 102.034 88.1817 97.0851 89.4726C96.87 89.4726 96.6548 89.4726 96.4396 89.6878C91.7062 90.3332 86.3272 87.1059 82.8847 84.7391C81.1635 83.4482 79.4422 81.9421 77.9361 80.436C77.2906 79.7905 76.43 78.9299 75.7845 78.2844C70.8359 72.9055 66.9631 66.2356 64.5963 59.1354C64.166 57.6293 63.7357 56.1232 63.9508 54.6171C63.9508 54.1868 64.166 53.9716 64.166 53.7565C64.5963 52.6807 65.4569 51.6049 66.7479 51.3897C67.8237 51.1746 68.6843 51.6049 69.7601 51.82C70.8359 52.2504 71.6965 52.6807 72.7723 53.3261C70.4056 50.5291 69.7601 45.7956 69.7601 42.3531C69.7601 42.1379 69.7601 41.7076 69.9753 41.7076C70.1904 41.4925 70.8359 41.7076 71.051 41.9228C74.4936 44.5047 74.4936 49.2381 75.9997 53.111C75.9997 50.3139 76.2148 47.5169 76.2148 44.7198C76.2148 42.7834 76.43 40.4167 77.9361 39.1257C78.1512 38.9106 78.3664 38.9106 78.5816 38.9106C79.0119 38.9106 79.227 39.3409 79.4422 39.5561C82.0241 44.0744 80.518 49.6685 80.9483 54.8322C81.1635 56.7687 81.3786 58.7051 81.8089 60.6415C82.0241 61.5021 82.4544 63.4385 82.8847 65.375C83.315 66.2356 83.7453 67.0962 84.3908 67.9569C84.606 68.3872 85.0363 68.8175 85.4666 69.0326C86.1121 69.6781 86.9727 70.3236 87.8333 70.9691C89.5546 71.8297 91.2759 72.4752 93.2123 72.9055C96.6548 73.5509 100.097 73.5509 103.54 73.7661C104.185 73.7661 104.831 73.9813 105.261 74.4116C105.476 74.8419 105.476 75.057 105.476 75.4874Z"
            fill="#FAEDEE"
          />
          <path
            d="M150.658 146.704C146.14 146.704 141.407 145.198 137.964 143.046C131.294 138.743 127.206 132.288 123.333 126.479L130.864 121.53C134.306 126.694 137.749 132.288 142.698 135.3C146.57 137.882 152.81 138.743 156.252 135.731C160.556 131.858 159.48 124.543 157.759 119.164C155.607 112.494 151.519 106.685 146.57 103.027L151.949 95.9267C158.619 100.875 163.783 108.191 166.365 116.582C170.023 127.985 168.732 137.022 162.277 142.616C159.05 145.413 154.746 146.704 150.658 146.704Z"
            fill="#FBA99D"
          />
          <path
            d="M159.911 75.2724C160.126 78.4998 159.051 81.7272 157.76 84.9545C152.811 97.864 147.647 110.773 141.623 123.253C140.332 125.619 139.256 128.201 137.965 130.568C136.244 134.01 134.092 137.883 130.219 138.744C126.992 139.389 123.98 137.883 121.183 136.377C120.322 135.947 119.462 135.301 119.031 134.441C118.601 133.58 118.816 132.504 119.031 131.644C121.613 118.734 126.562 106.685 131.51 94.4215C133.877 88.3971 136.459 82.1575 138.395 75.9179C139.902 71.6148 140.977 66.0207 146.571 65.5904C151.735 65.3752 159.481 69.4632 159.911 75.2724Z"
            fill="#FBA99D"
          />
          <path
            d="M99.8817 113.785C99.8817 114.216 99.8817 114.431 99.8817 114.861C99.6666 117.658 99.0211 120.24 97.515 122.607C95.7938 125.189 93.2119 127.34 90.1997 127.986C89.5542 128.201 88.9087 128.201 88.2632 128.201C85.4662 128.416 82.6691 127.555 80.0873 126.48C76.8599 125.189 73.6325 123.682 69.9749 123.467C68.0384 123.252 66.102 123.467 64.1656 123.898C63.0898 124.113 62.2292 124.543 61.1534 124.758C60.0776 125.189 59.0018 125.619 57.926 126.049C54.2683 127.77 50.6107 130.137 46.953 131.643C43.9408 132.934 40.2831 133.58 37.7012 131.643C36.4103 130.783 35.7648 129.277 35.1193 127.986C31.4617 121.101 28.2343 114.216 25.0069 107.115C24.7918 106.9 24.5766 106.47 24.5766 106.04C24.5766 104.964 25.8676 104.103 26.7282 103.673C26.9433 103.458 27.1585 103.458 27.3737 103.243C29.7404 102.167 31.892 101.306 34.4739 100.876C37.2709 100.661 40.2831 101.091 42.2195 103.027C43.5105 104.318 44.3711 105.824 45.2317 107.546C46.0924 109.482 47.1681 111.419 48.0288 113.355C50.6107 111.203 53.1926 109.052 55.9896 107.115C56.8502 106.47 57.7109 106.04 58.5715 105.609C60.0776 104.749 61.7989 103.888 63.5201 103.458C66.3172 102.382 69.3294 101.952 72.3416 101.736C73.8477 101.521 75.3538 101.306 76.8599 101.521C78.5811 101.521 80.3024 101.952 81.8085 102.167C85.8965 103.027 90.1997 103.888 94.2877 104.749L89.7693 104.318C93.427 105.179 97.7302 106.47 99.2363 109.912C99.8817 110.988 99.8817 112.494 99.8817 113.785Z"
            fill="#FAEDEE"
          />
          <path
            d="M83.9595 103.458C82.4534 109.267 83.7444 115.506 87.4021 120.455C87.6172 120.67 87.8324 121.1 88.2627 120.885C88.4778 120.885 88.4778 120.67 88.693 120.455C89.3385 118.949 89.5536 117.228 89.7688 115.722C90.1991 112.064 91.4901 106.255 90.1991 102.597C88.693 99.1544 84.605 100.66 83.9595 103.458Z"
            fill="#FBA99D"
          />
          <path
            d="M110.639 62.1468C108.918 64.5135 106.767 66.4499 104.83 68.6015C93.4268 80.8655 88.0479 97.8629 84.8205 114.43C83.9599 118.733 83.5296 123.467 86.1115 126.694C87.4024 128.2 89.1237 129.276 90.8449 130.137C93.642 131.643 96.2239 133.149 99.0209 134.655C102.248 136.376 105.691 138.313 109.348 138.528C113.652 138.743 117.955 136.591 121.182 133.579C124.41 130.567 126.561 126.694 128.713 122.821C134.092 112.924 139.04 102.812 143.343 92.484C144.634 89.4718 145.925 86.2444 146.356 83.017C147.001 78.2836 145.925 73.5501 145.065 69.0318C144.849 67.956 144.634 66.6651 143.774 65.8044C142.698 64.7287 140.977 64.7287 139.686 64.9438C130.219 65.3741 120.537 66.2348 111.5 64.0832C109.564 63.6529 107.412 63.0074 106.336 61.2861"
            fill="#FAEDEE"
          />
          <path
            d="M64.1659 124.112C63.0901 124.327 62.2295 124.758 61.1537 124.973C60.9385 121.961 60.5082 118.518 59.6476 115.291C58.787 112.494 57.7112 109.912 55.9899 107.33C56.8506 106.685 57.7112 106.254 58.5718 105.824C60.2931 108.621 61.584 111.418 62.4446 114.43C63.3053 117.658 63.9508 121.1 64.1659 124.112Z"
            fill="#FBA99D"
          />
          <path
            d="M93.4258 119.81C92.5651 121.961 92.9954 119.164 92.7803 121.746C92.7803 124.113 93.4258 126.48 94.7167 128.631C97.2986 132.289 101.817 134.441 106.12 135.947C109.993 137.238 114.081 137.883 117.954 135.947C122.687 133.58 126.345 127.986 125.915 122.607C125.484 115.507 117.093 111.849 110.854 111.204C103.108 110.343 99.235 113.14 93.2106 117.658V119.81H93.4258Z"
            fill="#FBA99D"
          />
          <path
            d="M153.455 51.6046C153.455 51.8197 153.24 52.25 153.24 52.4652C152.164 57.1987 150.873 61.717 150.443 66.6656C150.228 68.8172 150.658 72.69 148.507 74.1961C146.785 75.2719 143.773 74.6264 141.837 74.6264C135.812 74.1961 130.003 73.1203 123.763 73.1203C123.333 73.1203 122.903 73.1203 122.257 73.1203C120.106 72.9052 118.169 72.0445 116.448 70.9687C114.081 69.8929 111.93 68.602 109.563 67.7414C107.627 67.0959 105.475 66.6656 104.399 64.9443C103.108 63.2231 103.108 61.0715 102.893 59.1351C102.248 52.6803 102.463 45.7953 104.184 39.5557C105.26 35.6829 107.411 31.8101 110.639 29.013C117.739 22.5583 129.142 21.2673 137.964 25.5705C144.419 28.7979 150.013 34.392 152.595 41.277C153.67 44.2892 154.101 47.9469 153.455 51.6046Z"
            fill="#FAEDEE"
          />
          <path
            d="M153.455 51.6045C153.455 51.8197 153.24 52.25 153.24 52.4652C152.164 57.1986 150.873 61.7169 150.442 66.6656C150.227 68.8171 150.658 72.69 148.506 74.1961C146.785 75.2719 143.773 74.6264 141.836 74.6264C135.812 74.1961 130.003 73.1203 123.763 73.1203C123.333 73.1203 122.902 73.1203 122.257 73.1203C124.839 72.0445 127.636 72.0445 130.433 71.6142C139.254 70.1081 147 63.8685 150.442 55.6925C151.518 53.1106 152.164 50.3136 152.379 47.5165C152.594 45.365 152.594 43.2134 152.164 41.277C153.67 44.2892 154.1 47.9469 153.455 51.6045Z"
            fill="#FEFAFB"
            stroke="#FBA99D"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M134.091 69.4636C131.725 71.6152 128.928 73.1213 125.915 73.1213C120.752 73.1213 115.373 72.0455 110.639 69.6788C109.133 68.8181 107.842 67.9575 106.551 67.0969C105.906 66.4514 105.045 65.8059 104.615 65.1605C103.109 63.4392 102.248 61.5028 101.818 59.1361C100.527 54.1874 101.387 48.163 103.109 43.4296C105.045 38.4809 108.057 36.5445 113.221 36.3294C118.385 35.899 124.409 37.6203 129.358 39.987C129.573 40.2022 129.788 40.2022 130.003 40.4173C132.155 41.4931 134.091 42.7841 135.597 44.2902C142.913 50.9601 140.976 63.0089 134.091 69.4636Z"
            fill="#F6523B"
          />
          <path
            d="M122.688 126.264C122.688 126.48 122.688 126.48 122.688 126.695C122.688 127.771 122.473 128.631 122.042 129.492C120.536 133.58 116.233 136.162 111.93 137.453C107.627 138.744 103.108 138.959 99.0205 140.25C95.578 141.326 92.1355 142.832 89.5536 145.198C88.9081 145.844 88.2626 146.274 87.8323 146.92C87.8323 147.135 87.6171 147.135 87.6171 147.35C82.8837 153.159 81.5927 161.55 75.9986 166.714C75.5683 167.144 75.138 167.36 74.7077 167.575C74.2774 167.575 73.8471 167.36 73.4167 167.36C65.4559 163.917 58.1406 159.614 50.8252 155.096C49.7494 154.45 48.4585 153.59 47.813 152.514C47.3827 151.653 47.3827 150.792 47.813 149.932C48.8888 146.704 53.6222 142.832 55.7738 141.971C58.1406 141.11 60.9376 141.326 63.3043 142.186C65.6711 143.047 68.0378 144.553 69.9742 146.059C70.4045 143.047 71.2652 139.819 72.5561 137.022C72.9864 136.162 73.4167 135.301 73.8471 134.44C74.2774 133.58 74.9228 132.934 75.3532 132.074C76.6441 130.137 78.3654 128.416 80.0866 126.91C82.4534 124.758 85.0352 122.822 87.6171 121.101C91.49 118.519 95.578 116.152 99.8811 115.076C100.527 114.861 101.172 114.861 101.818 114.646C106.121 113.785 110.854 114.431 114.942 116.152C117.309 117.013 119.46 118.519 120.967 120.455C122.042 121.961 122.688 124.113 122.688 126.264Z"
            fill="#FAEDEE"
          />
          <path
            d="M89.3391 145.198C88.6936 145.843 88.0482 146.274 87.6179 146.919C87.6179 147.134 87.4027 147.134 87.4027 147.349C84.8208 144.122 81.8086 141.54 78.3661 139.604C76.86 138.743 75.1387 138.098 73.4175 137.452C73.2023 137.237 72.772 137.237 72.5568 137.022C72.9871 136.161 73.4175 135.301 73.8478 134.44C74.0629 134.44 74.2781 134.655 74.4933 134.655C76.2145 135.301 78.1509 135.946 79.8722 137.022C83.3147 138.958 86.7572 141.755 89.3391 145.198Z"
            fill="#FBA99D"
          />
          <path
            d="M187.45 89.0423C187.45 89.2575 187.45 89.6878 187.235 89.9029C186.589 92.2697 184.222 93.5606 181.856 93.9909C181.21 94.2061 180.35 94.2061 179.704 94.2061C177.983 94.4213 176.477 94.4213 174.756 94.8516C173.034 95.2819 171.313 96.3577 169.592 97.2183C167.871 98.0789 165.934 98.7244 164.213 99.1547C163.137 99.3699 162.061 99.585 160.985 99.8002C156.252 100.446 151.519 100.015 147 98.5092C144.203 97.6486 142.052 95.2819 140.761 92.2697C138.824 87.5362 139.255 81.5118 143.773 78.7148C149.582 75.0571 153.24 80.2209 157.973 82.3724C158.834 82.8027 159.695 83.0179 160.555 83.2331C161.631 83.4482 162.707 83.4482 163.783 83.4482C166.149 83.2331 168.516 82.5876 170.668 81.5118C174.54 79.5754 177.983 76.5632 181.425 73.7661C182.071 73.3358 182.716 72.6903 183.577 72.9055C184.438 73.1207 184.868 74.1964 184.868 75.2722V75.4874C184.653 76.348 184.222 77.2087 184.007 78.0693C183.577 81.9421 187.88 85.3846 187.45 89.0423Z"
            fill="#FAEDEE"
          />
          <path
            d="M185.73 98.2942L169.808 19.9769C169.593 19.1163 170.239 18.4708 170.884 18.2556C171.745 18.0405 172.39 18.6859 172.605 19.3314L188.527 97.6488C188.742 98.5094 188.097 99.1549 187.451 99.37C186.806 99.5852 185.945 98.9397 185.73 98.2942Z"
            fill="#FEFAFB"
            stroke="#FBA99D"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M190.678 87.751C190.463 87.1055 189.817 86.6752 189.172 86.6752L189.817 86.46C190.893 86.0297 191.323 84.7388 190.893 83.663C190.463 82.8024 189.387 82.3721 188.526 82.5872L188.957 82.3721C190.032 81.9417 190.463 80.6508 190.032 79.575C189.817 78.9295 189.387 78.7144 188.741 78.4992C189.602 77.8537 190.032 76.778 189.602 75.7022C189.172 74.6264 187.881 74.1961 186.805 74.6264L179.49 78.0689C178.414 78.4992 177.983 79.7902 178.414 80.866C178.629 81.5114 179.059 81.7266 179.705 81.9417C178.844 82.5872 178.414 83.663 178.844 84.7388C179.274 85.5994 180.35 86.0297 181.211 85.8146L180.565 86.0297C179.49 86.4601 179.059 87.751 179.49 88.8268C179.705 89.4723 180.35 89.9026 180.996 89.9026C180.135 90.548 179.92 91.6238 180.35 92.4845C180.781 93.5603 182.071 93.9906 183.147 93.5603L189.602 90.548C190.678 90.1177 191.108 89.0419 190.678 87.751Z"
            fill="#FDD4CE"
          />
          <path
            opacity="0.4"
            d="M126.131 48.593C125.27 49.8839 123.549 50.3143 122.258 49.6688C121.828 49.4536 121.182 49.2385 120.752 49.0233C114.727 46.6566 108.273 48.1627 106.552 52.681C106.121 53.7568 104.615 53.5416 104.615 52.4658C104.4 50.9597 104.615 49.2385 105.261 47.7324C107.412 41.9231 114.512 39.3412 121.182 41.9231C122.903 42.5686 124.41 43.6444 125.701 44.7202C126.776 45.796 126.776 47.3021 126.131 48.593Z"
            fill="#FEFAFB"
          />
          <path
            opacity="0.4"
            d="M105.476 81.5115C109.779 83.663 118.6 86.4601 123.979 86.8904C125.055 87.1056 125.486 87.9662 125.27 89.042C121.613 97.6483 118.6 106.255 117.525 109.482C117.31 110.343 116.449 110.773 115.803 110.558C111.931 109.482 101.173 106.47 96.8696 104.748C96.0089 104.533 95.7938 103.673 96.0089 102.812C99.6666 93.7754 102.248 85.8146 103.324 82.3721C103.755 81.5115 104.83 81.0812 105.476 81.5115Z"
            fill="#FBA99D"
          />
          <path
            d="M164.43 98.9389C163.354 99.154 162.278 99.3692 161.202 99.5843C159.481 97.0024 158.405 93.5599 158.62 90.1174C158.836 88.181 159.481 85.3839 160.772 83.2324C161.848 83.4475 162.924 83.4475 163.999 83.4475C162.708 85.3839 161.633 88.181 161.417 90.3326C161.202 93.5599 162.493 97.0024 164.43 98.9389Z"
            fill="#FBA99D"
          />
          <path
            d="M88.0485 70.9689C84.1757 73.5508 80.7331 76.7782 77.7209 80.4359C77.0755 79.7904 76.2148 78.9298 75.5694 78.2843C78.3664 74.8418 81.8089 71.8296 85.4666 69.2477L88.0485 70.9689Z"
            fill="#FBA99D"
          />
          <g filter="url(#filter0_d_395_1096)">
            <path
              d="M227.901 48.1621C207.676 41.4922 199.285 66.8808 178.63 56.1229L171.314 20.4067C193.691 29.8737 202.297 8.35792 220.585 12.4459L227.901 48.1621Z"
              fill="url(#paint0_linear_395_1096)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_395_1096"
              x="149.314"
              y="0.922928"
              width="100.586"
              height="90.8936"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_395_1096"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_395_1096"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_395_1096"
              x1="199.589"
              y1="10.8382"
              x2="199.589"
              y2="59.3221"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FBA99D" />
              <stop offset="0.9964" stopColor="#F87D6C" />
            </linearGradient>
          </defs>
        </svg>
      );

    case "cart":
      return (
        <svg
          className="lg:w-[300px] xs:w-[200px]"
          width="18rem"
          height="15rem"
          viewBox="0 0 372 299"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M44.6302 11.1782C47.5578 11.1782 49.8347 8.54802 49.8347 5.58909C49.8347 2.63016 47.5578 0 44.3049 0C41.3774 0 38.7751 2.30139 38.7751 5.58909C39.1004 8.54802 41.7027 11.1782 44.6302 11.1782Z"
            fill="#FBF1F2"
          />
          <path
            d="M39.0435 297.484H344.374"
            stroke="#FBF1F2"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M4.31533 297.484H30.403"
            stroke="#FBF1F2"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M43.3979 6.4342L29.852 9.15312L39.9489 59.8295L53.4948 57.1105L43.3979 6.4342Z"
            fill="#FAEDEE"
          />
          <path
            d="M93.1868 60.2252L84.3962 15.4788H13.4431C10.3036 15.4788 7.79199 14.2183 5.90828 12.3276L0.885055 6.65555C-0.998655 4.76486 0.257152 0.983475 3.39667 0.983475H90.0473C93.1868 0.983475 96.3264 3.5044 96.9543 6.65555L107.001 57.074L93.1868 60.2252Z"
            fill="#FAEDEE"
          />
          <path
            d="M299.767 296.561C313.291 296.561 324.255 285.557 324.255 271.982C324.255 258.408 313.291 247.403 299.767 247.403C286.242 247.403 275.279 258.408 275.279 271.982C275.279 285.557 286.242 296.561 299.767 296.561Z"
            fill="url(#paint0_linear_395_1071)"
          />
          <path
            d="M127.093 296.561C140.617 296.561 151.581 285.557 151.581 271.982C151.581 258.408 140.617 247.403 127.093 247.403C113.568 247.403 102.604 258.408 102.604 271.982C102.604 285.557 113.568 296.561 127.093 296.561Z"
            fill="url(#paint1_linear_395_1071)"
          />
          <path
            d="M124.963 213.807L111.425 216.568L122.927 273.389L136.464 270.629L124.963 213.807Z"
            fill="#FAEDEE"
          />
          <path
            d="M299.139 278.915H86.9077C83.7682 278.915 80.6287 276.394 80.0008 273.243L64.9311 200.136C64.3032 196.355 66.8148 192.573 70.5822 191.943C74.3497 191.313 78.1171 193.834 78.745 197.615L92.5589 265.05H299.767C303.534 265.05 306.674 268.201 306.674 271.982C306.674 275.764 303.534 278.915 299.139 278.915Z"
            fill="#FAEDEE"
          />
          <path
            d="M85.6519 296.561C99.1764 296.561 110.14 285.557 110.14 271.982C110.14 258.408 99.1764 247.403 85.6519 247.403C72.1275 247.403 61.1637 258.408 61.1637 271.982C61.1637 285.557 72.1275 296.561 85.6519 296.561Z"
            fill="url(#paint2_linear_395_1071)"
          />
          <path
            d="M85.6521 283.957C92.2409 283.957 97.5822 278.596 97.5822 271.982C97.5822 265.369 92.2409 260.008 85.6521 260.008C79.0632 260.008 73.7219 265.369 73.7219 271.982C73.7219 278.596 79.0632 283.957 85.6521 283.957Z"
            fill="#F6523B"
          />
          <path
            d="M258.325 296.561C271.85 296.561 282.813 285.557 282.813 271.982C282.813 258.408 271.85 247.403 258.325 247.403C244.801 247.403 233.837 258.408 233.837 271.982C233.837 285.557 244.801 296.561 258.325 296.561Z"
            fill="url(#paint3_linear_395_1071)"
          />
          <path
            d="M258.325 283.957C264.914 283.957 270.255 278.596 270.255 271.982C270.255 265.369 264.914 260.008 258.325 260.008C251.736 260.008 246.395 265.369 246.395 271.982C246.395 278.596 251.736 283.957 258.325 283.957Z"
            fill="#F6523B"
          />
          <g filter="url(#filter0_d_395_1071)">
            <path
              d="M349.999 55.1837L324.255 203.288C322.371 212.741 314.836 219.044 305.417 219.044H84.3955C74.9769 219.044 67.4421 212.741 65.5584 203.288L36.6748 55.1837H349.999Z"
              fill="url(#paint4_linear_395_1071)"
            />
          </g>
          <path
            d="M167.906 198.876C171.046 198.876 174.185 196.355 174.185 192.574V82.2834C174.185 79.1322 171.674 75.9811 167.906 75.9811C164.767 75.9811 161.627 78.502 161.627 82.2834V192.574C161.627 196.355 164.139 198.876 167.906 198.876Z"
            fill="#F87D6C"
          />
          <path
            d="M199.93 198.876C203.069 199.506 206.209 196.985 206.837 193.204L217.511 83.5435C218.139 80.3924 215.627 77.2412 211.86 76.611C208.72 75.9808 205.581 78.5017 204.953 82.2831L194.279 191.943C194.279 195.725 196.162 198.245 199.93 198.876Z"
            fill="#F87D6C"
          />
          <path
            d="M232.581 198.245C235.72 198.876 238.86 196.355 239.488 193.203L259.581 84.8038C260.209 81.6526 257.697 78.5015 254.557 77.8712C251.418 77.241 248.278 79.7619 247.65 82.9131L228.185 191.313C226.93 194.464 229.441 197.615 232.581 198.245Z"
            fill="#F87D6C"
          />
          <path
            d="M135.256 198.876C132.116 199.506 128.977 196.985 128.349 193.204L117.674 83.5435C117.046 80.3924 119.558 77.2412 123.325 76.611C126.465 75.9808 129.604 78.5017 130.232 82.2831L140.907 191.943C140.907 195.725 139.023 198.245 135.256 198.876Z"
            fill="#F87D6C"
          />
          <path
            d="M102.605 198.245C99.4657 198.876 96.3262 196.355 95.6983 193.203L75.6054 84.8038C74.9775 81.6526 77.4891 78.5015 80.6286 77.8712C83.7681 77.241 86.9076 79.7619 87.5356 82.9131L107.001 191.313C108.256 194.464 105.745 197.615 102.605 198.245Z"
            fill="#F87D6C"
          />
          <path
            d="M350 55.1811L324.256 203.285C322.372 212.739 314.837 219.041 305.419 219.041H247.024C257.698 219.041 266.489 211.478 268.373 201.395L295.372 55.1811H350Z"
            fill="#FBA99D"
          />
          <defs>
            <filter
              id="filter0_d_395_1071"
              x="14.6748"
              y="44.1837"
              width="357.324"
              height="207.86"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_395_1071"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_395_1071"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_395_1071"
              x1="275.256"
              y1="271.988"
              x2="324.264"
              y2="271.988"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F87D6C" />
              <stop offset="1" stopColor="#F96363" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_395_1071"
              x1="102.582"
              y1="271.988"
              x2="151.59"
              y2="271.988"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F87D6C" />
              <stop offset="1" stopColor="#F96363" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_395_1071"
              x1="61.1413"
              y1="271.988"
              x2="110.149"
              y2="271.988"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F87D6C" />
              <stop offset="1" stopColor="#F96363" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_395_1071"
              x1="233.814"
              y1="271.988"
              x2="282.822"
              y2="271.988"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F87D6C" />
              <stop offset="1" stopColor="#F96363" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_395_1071"
              x1="193.235"
              y1="51.3934"
              x2="193.235"
              y2="220.81"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F9E8EA" />
              <stop offset="0.9964" stopColor="#FBF1F2" />
            </linearGradient>
          </defs>
        </svg>
      );
  }
};

export default States;
