import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReferral } from "../../types";

const initialState: IReferral = {
  code: "",
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    setReferral: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
  },
});

export default referralSlice;
export const { setReferral } = referralSlice.actions;
