import { some } from "lodash";
import { Dispatch, SetStateAction } from "react";

import { checkOrder } from "../services";
import { IOrderResponse } from "../types";
import { addDataToLocalStorage, getDataFromLocalStorage } from "../helpers";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import ErrorHandler from "../helpers/functions/Errors/ErrorHandler";
import { setAlert } from "../store/slices/alertsSlice";
import { addTrackingDetails } from "../store/slices/trackingSlice";

const useHandleCheckOrder = (
  trackingState: Dispatch<SetStateAction<string[]>> | null = null
): ((arg: string) => void) => {
  const dispatch = useAppDispatch();
  const tracking = useAppSelector((state: RootState) => state.tracking);

  return (arg: string) => {
    if (!some(tracking, (track) => track.tracking_id.includes(arg)))
      checkOrder(arg)
        .then(({ data }: { data: IOrderResponse }) => {
          addDataToLocalStorage("trackingIDs", arg);

          const storedData = getDataFromLocalStorage("trackingIDs") ?? "[]";

          if (trackingState) {
            trackingState(JSON.parse(storedData));
          }

          dispatch(addTrackingDetails(data));
          dispatch(
            setAlert({
              type: "success",
              message: "Order info found successfully!",
            })
          );
        })
        .catch(({ response }) => {
          const { detail, status } = response.data;
          dispatch(ErrorHandler(status, detail));
        });
  };
};

export default useHandleCheckOrder;
