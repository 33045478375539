import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";

import Inputs from "./Inputs";
import TrackingHistory from "../TrackingHistory";

const Search: React.FC<{
  setSearch: Dispatch<SetStateAction<string>>;
  formHandler: any;
  placeholder?: string;
  trackingHistory?: string[];
  setTrackingHistory?: Dispatch<SetStateAction<string[]>>;
}> = ({
  setSearch,
  formHandler,
  placeholder = "Search...",
  trackingHistory,
  setTrackingHistory,
}) => {
  const [selectedId, setSelectedId] = useState<string>("");

  useEffect(() => {
    setSearch(selectedId);
  }, [selectedId, setSearch]);

  return (
    <StyledSearchForm onSubmit={formHandler} className="my-12 lg:my-4 relative">
      <Inputs
        type="search"
        setInputValue={setSearch}
        placeholder={placeholder}
        value={selectedId}
      />
      {trackingHistory && trackingHistory.length > 0 && (
        <TrackingHistory
          history={trackingHistory}
          setSearch={setSelectedId}
          setTrackingHistory={setTrackingHistory}
        />
      )}
    </StyledSearchForm>
  );
};

export default Search;

const StyledSearchForm = styled.form`
  @media screen and (min-width: 120em) {
    width: 1800px !important;
    margin: 2rem auto;
  }
`;
