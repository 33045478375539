import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IOrderResponse } from "../../types";
import { some } from "lodash";

const initialState: IOrderResponse[] = [];

const trackingSlice = createSlice({
  name: "trackingSlice",
  initialState,
  reducers: {
    addTrackingDetails: (state, action: PayloadAction<IOrderResponse>) => {
      const { payload } = action;
      const { tracking_id } = payload;

      if (!some(state, ["trackingID", tracking_id])) {
        state.push(payload);
      }
    },
    resetTrackingDetails: (state) => {
      state.length = 0;
    },
  },
});

export default trackingSlice;

export const { addTrackingDetails, resetTrackingDetails } =
  trackingSlice.actions;
